import { EmbeddedScene, SceneGridItem, SceneGridLayout } from '@grafana/scenes';
import { getRecentBuildsPanel } from 'panels/recentBuilds';
import { percentageSuccessfulBuild, statusTimelineData } from './data';
import { getStagesTable, getStatusTimelinePanel } from './panels';
import { getRecentBuildsQuery } from 'queries/recentBuilds';
import { getBuildsByStatusPanel } from 'panels/buildsByStatus';
import { getBuildsByStatusQuery } from 'queries/buildByStatus';

export const getRepositoriesOverviewScene = () => {
  const ROW_HEIGHT = 8;
  const PANEL_WIDTH = 12;

  return new EmbeddedScene({
    body: new SceneGridLayout({
      children: [
        new SceneGridItem({
          x: 0,
          y: 0,
          width: 24,
          height: ROW_HEIGHT,
          $data: statusTimelineData('${repo}'),
          body: getStatusTimelinePanel(),
        }),
        new SceneGridItem({
          x: 0,
          y: ROW_HEIGHT,
          width: 24,
          height: ROW_HEIGHT,
          $data: getBuildsByStatusQuery('${repo}'),
          body: getBuildsByStatusPanel(),
        }),

        new SceneGridItem({
          y: ROW_HEIGHT * 2,
          x: 0,
          height: ROW_HEIGHT,
          width: PANEL_WIDTH,
          $data: percentageSuccessfulBuild('$repo'),
          body: getStagesTable(),
        }),
        new SceneGridItem({
          y: ROW_HEIGHT * 2,
          x: PANEL_WIDTH,
          height: ROW_HEIGHT,
          width: PANEL_WIDTH,
          $data: getRecentBuildsQuery('$repo'),
          body: getRecentBuildsPanel('${repo}'),
        }),
      ],
    }),
  });
};

import { EmbeddedScene, SceneFlexItem, SceneFlexLayout } from '@grafana/scenes';
import { getRecentBuildsPanel } from 'panels/recentBuilds';
import { getRecentBuildsQuery } from 'queries/recentBuilds';

export const getBuildsOverview = (repoSlug: string) => () => {
  return new EmbeddedScene({
    body: new SceneFlexLayout({
      direction: 'row',
      children: [
        new SceneFlexItem({
          $data: getRecentBuildsQuery(repoSlug),
          body: getRecentBuildsPanel(repoSlug),
        }),
      ],
    }),
  });
};

import { PanelBuilders } from '@grafana/scenes';
import {
  BarGaugeDisplayMode,
  BarGaugeValueMode,
  MappingType,
  TableCellDisplayMode,
  ThresholdsMode,
} from '@grafana/schema';
import { ROUTES } from '../../constants';
import { prefixRoute } from 'utils/utils.routing';
import { FieldType } from '@grafana/data';
import { LABELS } from 'transformations/common';

export const getStagesTable = () =>
  PanelBuilders.table()
    .setOverrides((builder) => {
      builder
        .matchFieldsWithName('Successful builds')
        .overrideUnit('percentunit')
        .overrideCustomFieldConfig('cellOptions', {
          type: TableCellDisplayMode.Gauge,
          valueDisplayMode: BarGaugeValueMode.Color,
          mode: BarGaugeDisplayMode.Lcd,
        })
        .matchFieldsWithName('Stage name')
        .overrideLinks([
          {
            title: 'View ${__value.text} stats',
            url: prefixRoute(ROUTES.RepositoriesOverview) + '/${repo}/stage/${__value.text}',
          },
        ])
        .overrideMin(0)
        .overrideMax(1)
        .overrideThresholds({
          mode: ThresholdsMode.Percentage,
          steps: [
            {
              value: 0,
              color: 'red',
            },
            {
              value: 0.85,
              color: 'green',
            },
          ],
        })
        .build();
    })
    .build();

export const getStatusTimelinePanel = () =>
  PanelBuilders.statetimeline()
    .setTitle('Status timeline')
    .setCustomFieldConfig('fillOpacity', 100)
    .setDisplayMode('transparent')
    .setOverrides((builder) => {
      builder.matchFieldsByType(FieldType.string).overrideMappings([
        {
          type: MappingType.ValueToText,
          options: {
            success: {
              text: LABELS.SUCCESS,
              color: 'green',
            },
            killed: {
              text: LABELS.KILLED,
              color: 'red',
            },
            failure: {
              text: LABELS.FAILURE,
              color: 'semi-dark-red',
            },
            error: {
              text: LABELS.ERROR,
              color: 'dark-red',
            },
          },
        },
      ]);
    })
    .build();

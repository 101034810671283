import React from 'react';
import { css } from '@emotion/css';
import { GrafanaTheme2, IconName } from '@grafana/data';
import { Icon, Tooltip, useStyles2 } from '@grafana/ui';
import { capitalize } from 'lodash';
import { Status } from 'types';

interface BuildStatusIconProps {
  status?: Status;
}
export const BuildStatusIcon = ({ status }: BuildStatusIconProps) => {
  const styles = useStyles2(getStyles);

  return (
    <Tooltip content={capitalize(status) || 'Unknown'}>
      <Icon name={getIcon(status)} className={styles[status || 'Unknown']} size="lg" />
    </Tooltip>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  [Status.Failure]: css`
    color: ${theme.colors.error.main};
  `,
  [Status.Error]: css`
    color: ${theme.colors.error.main};
  `,
  [Status.Killed]: css`
    color: ${theme.colors.error.main};
  `,
  [Status.Success]: css`
    color: ${theme.colors.success.main};
  `,
  [Status.Skipped]: css`
    color: ${theme.colors.secondary.shade};
  `,
  Unknown: css`
    color: ${theme.colors.text.secondary};
  `,
});

const getIcon = (status?: Status): IconName => {
  switch (status) {
    case Status.Failure:
      return 'times-circle';
    case Status.Success:
      return 'check-circle';
    case Status.Error:
      return 'exclamation-circle';
    case Status.Killed:
      return 'minus-circle';
    case Status.Skipped:
      return 'minus-circle';
    default:
      return 'question-circle';
  }
};

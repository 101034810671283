import React, { useState, useCallback, FormEventHandler } from 'react';
import { Button, Field } from '@grafana/ui';
import { PluginConfigPageProps, AppPluginMeta, PluginMeta } from '@grafana/data';
import { DataSourcePicker, getBackendSrv } from '@grafana/runtime';
import { lastValueFrom } from 'rxjs';
import { GracieSettings } from '../../types';

interface Props extends PluginConfigPageProps<AppPluginMeta<GracieSettings>> {}

export const AppConfig = ({ plugin, query }: Props) => {
  const { jsonData, id, enabled, pinned } = plugin.meta;
  const [tracesDS, setTracesDS] = useState(jsonData?.tracesDS);
  const [metricsDS, setMetricsDS] = useState(jsonData?.metricsDS);
  const [logsDS, setLogsDS] = useState(jsonData?.logsDS);

  const handleSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault();
      updatePluginAndReload(id, { jsonData: { tracesDS, metricsDS, logsDS }, enabled, pinned });
    },
    [id, tracesDS, metricsDS, logsDS, enabled, pinned]
  );

  return (
    <form onSubmit={handleSubmit}>
      <Field label="Metrics data source" htmlFor="metricsDs">
        <DataSourcePicker
          inputId="metricsDs"
          metrics
          noDefault
          type="prometheus"
          current={metricsDS}
          onChange={({ uid }) => setMetricsDS(uid)}
          width={40}
        />
      </Field>

      <Field label="Logging data source" htmlFor="logsDS">
        <DataSourcePicker
          inputId="logsDS"
          logs
          noDefault
          type="loki"
          current={logsDS}
          onChange={({ uid }) => setLogsDS(uid)}
          width={40}
        />
      </Field>

      <Field label="Tracing data source" htmlFor="traceDs">
        <DataSourcePicker
          inputId="traceDs"
          tracing
          noDefault
          type="tempo"
          current={tracesDS}
          onChange={({ uid }) => setTracesDS(uid)}
          width={40}
        />
      </Field>

      <Button type="submit" icon="save">
        Save
      </Button>
    </form>
  );
};

const updatePluginAndReload = async (pluginId: string, data: Partial<PluginMeta<GracieSettings>>) => {
  try {
    await updatePlugin(pluginId, data);

    // Reloading the page as the changes made here wouldn't be propagated to the actual plugin otherwise.
    // This is not ideal, however unfortunately currently there is no supported way for updating the plugin state.
    window.location.reload();
  } catch (e) {
    console.error('Error while updating the plugin', e);
  }
};

export const updatePlugin = async (pluginId: string, data: Partial<PluginMeta>) => {
  const response = getBackendSrv().fetch({
    url: `/api/plugins/${pluginId}/settings`,
    method: 'POST',
    data,
  });

  return lastValueFrom(response);
};

import pluginJson from './plugin.json';

export const PLUGIN_BASE_URL = `/a/${pluginJson.id}`;

export const DATASOURCES = {
  METRICS: {
    uid: '$_metrics_ds',
    type: 'prometheus',
  },
  LOGS: {
    uid: '$_logs_ds',
    type: 'loki',
  },
  TRACES: {
    uid: '$_traces_ds',
    type: 'tempo',
  },
};

export const ROUTES = {
  Overview: 'overview',
  RepositoriesOverview: 'repositories',
};

import { Status, Tag } from 'types';
import { CI } from 'semanticConventions';
import { PanelData } from '@grafana/data';

export const getStages = (data: PanelData | undefined) => {
  interface Step {
    name: string;
    status: Status;
    duration: number;
    number: number;
    spanID: string;
  }

  interface Stage {
    name: string;
    status: Status;
    duration: number;
    number: number;
    steps: Step[];
  }

  if (!data?.series[0]?.fields) {
    return;
  }

  const fields = Object.fromEntries(
    data.series[0]?.fields.map(({ name, values }) => {
      return [
        name,
        name === 'tags'
          ? values.map((tags: Tag[]) => Object.fromEntries(tags.map((tag) => [tag.key, tag.value])))
          : values,
      ];
    })
  );

  const stages = fields['tags']
    ?.reduce<Stage[]>((stages, _, stageIndex) => {
      if (fields['tags'][stageIndex][CI.DRONE.WORKFLOW_ITEM.KIND] === 'stage') {
        return [
          ...stages,
          {
            name: fields['operationName']?.[stageIndex],
            status: fields['tags'][stageIndex][CI.WORKFLOW_ITEM.STATUS],
            duration: fields['duration'][stageIndex],
            number: fields['tags'][stageIndex][CI.DRONE.STAGE.NUMBER],
            steps: fields['tags']
              ?.reduce<Step[]>((steps, _, stepIndex) => {
                if (fields['spanID'][stageIndex] === fields['parentSpanID'][stepIndex]) {
                  return [
                    ...steps,
                    {
                      name: fields['operationName']?.[stepIndex],
                      number: fields['tags'][stepIndex][CI.DRONE.STEP.NUMBER],
                      duration: fields['duration'][stepIndex],
                      status: fields['tags'][stepIndex][CI.WORKFLOW_ITEM.STATUS],
                      spanID: fields['spanID'][stepIndex],
                    },
                  ];
                }
                return steps;
              }, [])
              .sort((a, b) => a.number - b.number),
          },
        ];
      }

      return stages;
    }, [])
    .sort((a, b) => a.number - b.number);

  return stages;
};

import React, { useMemo } from 'react';
import {
  ConstantVariable,
  SceneApp,
  SceneAppPage,
  SceneRefreshPicker,
  SceneTimePicker,
  SceneTimeRange,
  SceneVariableSet,
  VariableValueSelectors,
} from '@grafana/scenes';
import { ROUTES } from '../../constants';
import { prefixRoute } from 'utils/utils.routing';
import { getRepositoriesOverviewScene } from './scenes';
import { SceneConstantVariableState, useDatasourcesSceneVariables } from 'pages/utils';
import { getRepositoryDrilldownPage } from './drilldowns/Repository';
import { getBranchQueryVariable, getRepoQueryVariable } from './data';

const getScene = (initialVariables: SceneConstantVariableState[]) => {
  return new SceneApp({
    pages: [
      new SceneAppPage({
        title: `Repositories`,
        $timeRange: new SceneTimeRange({ from: 'now-7d', to: 'now' }),
        $variables: new SceneVariableSet({
          variables: [
            ...initialVariables.map((state) => new ConstantVariable(state)),
            getRepoQueryVariable(),
            getBranchQueryVariable('$repo'),
          ],
        }),
        controls: [
          new VariableValueSelectors({}),
          new SceneTimePicker({ isOnCanvas: true }),
          new SceneRefreshPicker({ isOnCanvas: true }),
        ],
        url: prefixRoute(ROUTES.RepositoriesOverview),
        drilldowns: [
          {
            routePath: `${prefixRoute(ROUTES.RepositoriesOverview)}/:repoOrg/:repoName`,
            getPage: getRepositoryDrilldownPage(initialVariables),
          },
        ],
        getScene: getRepositoriesOverviewScene,
      }),
    ],
  });
};

export const RepositoriesOverview = () => {
  const variables = useDatasourcesSceneVariables();
  const scene = useMemo(() => getScene(variables), [variables]);

  return <scene.Component model={scene} />;
};

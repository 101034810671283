import { SceneDataTransformer, SceneQueryRunner } from '@grafana/scenes';
import { DATASOURCES } from '../constants';
import { CI, toPromLabel, VCS } from 'semanticConventions';
import { JOIN_BY_TIME, RENAME_BY_NAME_CONFIG } from 'transformations/common';

export const getBuildsByStatusQuery = (repoSlug: string) =>
  new SceneDataTransformer({
    $data: new SceneQueryRunner({
      datasource: DATASOURCES.METRICS,
      queries: [
        {
          refId: 'A',
          expr: `sum(ci_builds_number{${toPromLabel(VCS.GIT.REPO.NAME)}=~"${repoSlug}"}) by (${toPromLabel(
            CI.WORKFLOW_ITEM.STATUS
          )})`,
          legendFormat: `{{${toPromLabel(CI.WORKFLOW_ITEM.STATUS)}}}`,
        },
      ],
    }),
    transformations: [
      JOIN_BY_TIME,
      {
        id: 'organize',
        options: {
          indexByName: {
            Time: 0,
            success: 1,
            running: 2,
            failure: 3,
            error: 4,
            killed: 5,
            blocked: 6,
            declined: 7,
            pending: 8,
            waiting_on_dependencies: 9,
            skipped: 10,
          },
          renameByName: RENAME_BY_NAME_CONFIG,
        },
      },
    ],
  });

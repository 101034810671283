export interface GracieSettings {
  tracesDS?: string;
  metricsDS?: string;
  logsDS?: string;
}

export enum Status {
  Error = 'error',
  Failure = 'failure',
  Killed = 'killed',
  Success = 'success',
  Skipped = 'skipped',
}

export interface Tag {
  key: string;
  value: string;
}

export const CI = {
  WORKFLOW_ITEM: {
    STATUS: 'ci.workflow_item.status',
  },
  DRONE: {
    WORKFLOW_ITEM: {
      KIND: 'ci.drone.workflow_item.kind',
    },
    BUILD: {
      NUMBER: 'ci.drone.build.number',
    },
    STAGE: {
      NUMBER: 'ci.drone.stage.number',
      NAME: 'ci.drone.stage.name',
    },
    STEP: {
      NUMBER: 'ci.drone.step.number',
    },
  },
} as const;

export const VCS = {
  GIT: {
    REPO: {
      NAME: 'git.repo.name',
    },
    BRANCH: {
      NAME: 'git.branch.name',
    },
  },
};

export const toPromLabel = (label: string) => label.replace(/\./g, '_');

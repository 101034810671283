import {
  ConstantVariable,
  QueryRunnerState,
  SceneAppPage,
  SceneAppPageLike,
  SceneQueryRunner,
  SceneRouteMatch,
  SceneTimeRange,
  SceneVariableSet,
} from '@grafana/scenes';
import { DATASOURCES, ROUTES } from '../../../../../../../../constants';
import { prefixRoute } from 'utils/utils.routing';
import { SceneConstantVariableState } from 'pages/utils';
import { getTraceTab } from './tabs/trace';
import { getBuildTab } from './tabs/build';
import { dateTime } from '@grafana/data';
import { CI, VCS } from 'semanticConventions';

interface BuildURLParams {
  repoName: string;
  repoOrg: string;
  buildNumber: string;
}

export const getBuildDrilldownPage =
  (initialVariables: SceneConstantVariableState[]) =>
  ({ params: { repoOrg, repoName, buildNumber } }: SceneRouteMatch<BuildURLParams>, parent: SceneAppPageLike) => {
    const traceTimeRange = new SceneTimeRange();

    const tempoSearch = new SceneQueryRunner({
      datasource: DATASOURCES.TRACES,
      $timeRange: new SceneTimeRange({
        from: 'now-5d',
        to: 'now',
      }),
      $variables: new SceneVariableSet({
        variables: initialVariables.map((state) => new ConstantVariable(state)),
      }),
      queries: [
        {
          refId: 'A',
          queryType: 'traceql',
          query: `{resource.${VCS.GIT.REPO.NAME}="${repoOrg}/${repoName}" && span.${CI.DRONE.BUILD.NUMBER}=${buildNumber}}`,
        },
      ],
    });

    const traceQuery = new SceneQueryRunner({
      datasource: DATASOURCES.TRACES,
      queries: [],
    });

    tempoSearch.subscribeToState((state) => {
      if (!state.data || state.data.state === 'Loading') {
        return;
      }

      // Set timerange to match the span to improve logs query performance
      const fromRaw = getFieldValue(state, 'startTime');
      const from = dateTime(fromRaw);

      const duration = getFieldValue(state, 'traceDuration');
      const to = dateTime(from).add(duration + 1000, 'milliseconds');

      // This automatically checks if the timerange already has the set value
      traceTimeRange.onTimeRangeChange({
        raw: {
          from,
          to,
        },
        from,
        to,
      });

      const traceId = getFieldValue(state, 'traceID');

      if (traceId) {
        traceQuery.setState({
          queries: [
            {
              refId: 'trace',
              queryType: 'traceql',
              query: traceId,
            },
          ],
        });
        traceQuery.runQueries();
      }
    });

    const buildTab = new SceneAppPage({
      title: 'Build',
      titleIcon: 'cube',
      url: `${prefixRoute(ROUTES.RepositoriesOverview)}/${repoOrg}/${repoName}/builds/${buildNumber}`,
      getScene: getBuildTab(repoOrg, repoName, buildNumber, traceTimeRange, traceQuery),
    });

    const traceTab = new SceneAppPage({
      title: 'Trace',
      url: `${prefixRoute(ROUTES.RepositoriesOverview)}/${repoOrg}/${repoName}/builds/${buildNumber}/trace`,
      getScene: getTraceTab(),
    });

    const app = new SceneAppPage({
      title: `#${buildNumber}`,
      subTitle: `${repoOrg}/${repoName}`,
      $timeRange: new SceneTimeRange({
        from: 'now-5d',
        to: 'now',
      }),
      $data: traceQuery,
      url: `${prefixRoute(ROUTES.RepositoriesOverview)}/${repoOrg}/${repoName}/builds/${buildNumber}`,
      $variables: new SceneVariableSet({
        variables: [...initialVariables.map((state) => new ConstantVariable(state))],
      }),
      getParentPage: () => parent,
      tabs: [buildTab, traceTab],
    });

    app.addActivationHandler(() => {
      return tempoSearch.activate();
    });

    return app;
  };

const getFieldValue = (state: QueryRunnerState, fieldName: string) => {
  return state.data?.series
    ?.find((series) => series.name === 'Traces')
    ?.fields.find((field) => field.name === fieldName)?.values[0];
};

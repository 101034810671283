import { useContext, createContext } from 'react';
import { AppRootProps } from '@grafana/data';
import { GracieSettings } from 'types';

// This is used to be able to retrieve the root plugin props anywhere inside the app.
export const PluginPropsContext = createContext<AppRootProps<GracieSettings> | null>(null);

export const usePluginProps = () => {
  const pluginProps = useContext(PluginPropsContext);

  return pluginProps;
};

export const usePluginMeta = () => {
  const pluginProps = usePluginProps();

  return pluginProps?.meta;
};

export const usePluginSettings = () => {
  const meta = usePluginMeta();

  return meta?.jsonData;
};

export const useDatasourceRefs = (): Record<'logs' | 'metrics' | 'traces', string | undefined> => {
  const settings = usePluginSettings();

  return {
    logs: settings?.logsDS,
    metrics: settings?.metricsDS,
    traces: settings?.tracesDS,
  };
};

import {
  ConstantVariable,
  SceneAppPage,
  SceneAppPageLike,
  SceneRefreshPicker,
  SceneRouteMatch,
  SceneTimePicker,
  SceneTimeRange,
  SceneVariableSet,
  VariableValueSelectors,
} from '@grafana/scenes';
import { ROUTES } from '../../../../constants';
import { prefixRoute } from 'utils/utils.routing';
import { getRepositoryOverviewScene } from './scenes';
import { getStageDrilldownPage } from 'pages/RepositoriesOverview/drilldowns/Repository/drilldowns/StageOverview';
import { getBuildsDrilldownPage } from './drilldowns/BuildsOverview';
import { SceneConstantVariableState } from 'pages/utils';
import { getBranchQueryVariable } from 'pages/RepositoriesOverview/data';

interface RepositoryURLParams {
  repoName: string;
  repoOrg: string;
}

export const getRepositoryDrilldownPage =
  (initialVariables: SceneConstantVariableState[]) =>
  ({ params: { repoOrg, repoName } }: SceneRouteMatch<RepositoryURLParams>, parent: SceneAppPageLike) => {
    const baseURL = `${prefixRoute(ROUTES.RepositoriesOverview)}/:repoOrg/:repoName`;
    const $timeRange = new SceneTimeRange();

    return new SceneAppPage({
      title: `${repoOrg}/${repoName}`,
      $timeRange,
      $variables: new SceneVariableSet({
        variables: [
          ...initialVariables.map((state) => new ConstantVariable(state)),
          getBranchQueryVariable(`${repoOrg}/${repoName}`),
        ],
      }),
      controls: [
        new VariableValueSelectors({}),
        new SceneTimePicker({ isOnCanvas: true }),
        new SceneRefreshPicker({ isOnCanvas: true }),
      ],
      getParentPage: () => parent,
      url: `${prefixRoute(ROUTES.RepositoriesOverview)}/${repoOrg}/${repoName}`,
      drilldowns: [
        {
          routePath: `${baseURL}/builds`,
          getPage: getBuildsDrilldownPage(initialVariables),
        },
        {
          routePath: `${baseURL}/stage/:stageName`,
          getPage: getStageDrilldownPage(initialVariables),
        },
      ],
      getScene: () => getRepositoryOverviewScene(`${repoOrg}/${repoName}`),
    });
  };

import { DataFrame } from '@grafana/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomTransformOperator } from '@grafana/scenes';

export const traceCleanup: CustomTransformOperator = () => (source: Observable<DataFrame[]>) => {
  return source.pipe(
    map((data: DataFrame[]) => {
      return data[0]?.fields.find((field) => field.name === 'nested')?.values.map((value) => value[0]) || [];
    })
  );
};

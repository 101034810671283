export const JOIN_BY_TIME = {
  id: 'joinByField',
  options: {
    byField: 'Time',
    mode: 'outer',
  },
};

export const LABELS = {
  RUNNING: 'Running',
  PENDING: 'Pending',
  WAITING_ON_DEPENDENCIES: 'Waiting on Dependencies',
  BLOCKED: 'Blocked',
  DECLINED: 'Declined',
  ERROR: 'Error',
  FAILURE: 'Failure',
  KILLED: 'Killed',
  PAUSED: 'Paused',
  SKIPPED: 'Skipped',
  SUCCESS: 'Success',
};

export const RENAME_BY_NAME_CONFIG = {
  running: LABELS.RUNNING,
  pending: LABELS.PENDING,
  waiting_on_dependencies: LABELS.WAITING_ON_DEPENDENCIES,
  blocked: LABELS.BLOCKED,
  declined: LABELS.DECLINED,
  error: LABELS.ERROR,
  failure: LABELS.FAILURE,
  killed: LABELS.KILLED,
  paused: LABELS.PAUSED,
  skipped: LABELS.SKIPPED,
  success: LABELS.SUCCESS,
};

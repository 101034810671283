import {
  SceneAppPage,
  SceneVariableSet,
  SceneRouteMatch,
  SceneAppPageLike,
  VariableValueSelectors,
  SceneTimePicker,
  SceneRefreshPicker,
  SceneTimeRange,
  ConstantVariable,
} from '@grafana/scenes';
import { ROUTES } from '../../../../../../constants';
import { prefixRoute } from 'utils/utils.routing';
import { getStageScene } from './scenes';
import { SceneConstantVariableState } from 'pages/utils';

interface StageURLParams {
  repoOrg: string;
  repoName: string;
  stageName: string;
}

export const getStageDrilldownPage =
  (initialVariables: SceneConstantVariableState[]) =>
  ({ params: { repoOrg, repoName, stageName } }: SceneRouteMatch<StageURLParams>, parent: SceneAppPageLike) => {
    return new SceneAppPage({
      title: `Stage: ${stageName} (overview)`,
      subTitle: `${repoOrg}/${repoName}`,
      $timeRange: new SceneTimeRange(),
      url: `${prefixRoute(ROUTES.RepositoriesOverview)}/${repoOrg}/${repoName}/stage/${stageName}`,
      $variables: new SceneVariableSet({
        variables: initialVariables.map((state) => new ConstantVariable(state)),
      }),
      controls: [
        new VariableValueSelectors({}),
        new SceneTimePicker({ isOnCanvas: true }),
        new SceneRefreshPicker({ isOnCanvas: true }),
      ],
      getParentPage: () => parent,
      getScene: getStageScene(`${repoOrg}/${repoName}`, stageName),
    });
  };

import {
  ConstantVariable,
  SceneAppPage,
  SceneAppPageLike,
  SceneRefreshPicker,
  SceneRouteMatch,
  SceneTimePicker,
  SceneTimeRange,
  SceneVariableSet,
} from '@grafana/scenes';
import { ROUTES } from '../../../../../../constants';
import { getBuildDrilldownPage } from 'pages/RepositoriesOverview/drilldowns/Repository/drilldowns/BuildsOverview/drilldowns/BuildOverview';
import { prefixRoute } from 'utils/utils.routing';
import { getBuildsOverview } from './scenes';
import { SceneConstantVariableState } from 'pages/utils';

interface BuildURLParams {
  repoName: string;
  repoOrg: string;
}

export const getBuildsDrilldownPage =
  (initialVariables: SceneConstantVariableState[]) =>
  ({ params: { repoOrg, repoName } }: SceneRouteMatch<BuildURLParams>, parent: SceneAppPageLike) => {
    const repoBaseUrl = prefixRoute(ROUTES.RepositoriesOverview) + '/:repoOrg/:repoName';

    return new SceneAppPage({
      title: `Builds`,
      subTitle: `${repoOrg}/${repoName}`,
      $timeRange: new SceneTimeRange(),
      url: `${prefixRoute(ROUTES.RepositoriesOverview)}/${repoOrg}/${repoName}/builds`,
      controls: [new SceneTimePicker({ isOnCanvas: true }), new SceneRefreshPicker({ isOnCanvas: true })],
      $variables: new SceneVariableSet({
        variables: initialVariables.map((state) => new ConstantVariable(state)),
      }),
      getParentPage: () => parent,
      drilldowns: [
        {
          routePath: `${repoBaseUrl}/builds/:buildNumber`,
          getPage: getBuildDrilldownPage(initialVariables),
        },
      ],
      getScene: getBuildsOverview(`${repoOrg}/${repoName}`),
    });
  };

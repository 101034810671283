import { PanelBuilders } from '@grafana/scenes';
import {
  LegendDisplayMode,
  LineInterpolation,
  ScaleDistribution,
  TooltipDisplayMode,
  VisibilityMode,
} from '@grafana/schema';
import { colorByStatusOverrideBuilder } from 'overrides/statusColor';

export const getBuildsByStatusPanel = () =>
  PanelBuilders.timeseries()
    .setTitle('Builds by status')
    .setOption('tooltip', { mode: TooltipDisplayMode.Multi })
    .setCustomFieldConfig('lineInterpolation', LineInterpolation.Linear)
    .setCustomFieldConfig('showPoints', VisibilityMode.Never)
    .setCustomFieldConfig('spanNulls', true)
    .setCustomFieldConfig('scaleDistribution', {
      type: ScaleDistribution.Symlog,
      log: 2,
      linearThreshold: 10,
    })
    .setOption('legend', {
      placement: 'right',
      displayMode: LegendDisplayMode.Table,
    })
    .setOverrides(colorByStatusOverrideBuilder)
    .build();

import React, { useMemo } from 'react';
import { CustomVariable, SceneComponentProps, sceneGraph, SceneObjectBase, SceneObjectState } from '@grafana/scenes';
import { CollapsableSection, CustomScrollbar, useStyles2 } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { css, cx } from '@emotion/css';
import { BuildStatusIcon } from 'components/BuildStatusIcon';
import { ROUTES } from '../../../../../../../../../../../constants';
import { prefixRoute } from 'utils/utils.routing';
import { getStages } from '../utils';

interface SideNavState extends SceneObjectState {
  buildNumber: string;
  repoName: string;
  repoOrg: string;
}

export class SideNav extends SceneObjectBase<SideNavState> {
  static Component = SideNavRenderer;

  constructor(state: Pick<SideNavState, 'buildNumber' | 'repoName' | 'repoOrg'>) {
    super({ ...state });
  }
}

function SideNavRenderer({ model }: SceneComponentProps<SideNav>) {
  const { buildNumber, repoName, repoOrg } = model.useState();
  const { data } = sceneGraph.getData(model).useState();
  const styles = useStyles2(getStyles);

  const variables = sceneGraph.getVariables(model);

  const spanIDVar = variables.getByName('spanID') as CustomVariable;
  const { value: spanId } = spanIDVar.useState();

  const stages = useMemo(() => getStages(data), [data]);

  return (
    <div className={styles.root}>
      <CustomScrollbar hideHorizontalTrack>
        <ul>
          {stages?.map((stage) => (
            <li key={stage.number}>
              <CollapsableSection
                className={cx(styles.collapse, {
                  [styles.activeStage]: stage.steps.some((step) => step.spanID === spanId),
                })}
                label={
                  <div className={styles.stage}>
                    <span>
                      <BuildStatusIcon status={stage.status} /> {stage.name}
                    </span>
                    <span className={styles.duration}>{formatDuration(stage.duration)}</span>
                  </div>
                }
                isOpen={true}
              >
                <ul className={styles.steps}>
                  {stage.steps.map((step, i) => (
                    <li key={i}>
                      <a
                        // TODO: would be nice if this linkl preserved the time range
                        href={`${prefixRoute(
                          ROUTES.RepositoriesOverview
                        )}/${repoOrg}/${repoName}/builds/${buildNumber}?var-spanID=${step.spanID}`}
                        className={cx(styles.step, {
                          [styles.activeStep]: spanId === step.spanID,
                        })}
                      >
                        <span className={styles.stepContent}>
                          <BuildStatusIcon status={step.status} />
                          {step.name}
                        </span>
                        <span className={styles.duration}>{formatDuration(step.duration)}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              </CollapsableSection>
            </li>
          ))}
        </ul>
      </CustomScrollbar>
    </div>
  );
}

const getStyles = (theme: GrafanaTheme2) => ({
  root: css({
    width: theme.spacing(48),
    listStyle: 'none',
    borderColor: theme.colors.border.weak,
    borderWidth: 0,
    borderRightWidth: '1px',
    borderStyle: 'solid',
  }),
  // !FIXME: we shouldn't be overriding styles this way, but grafana/ui does not have a component that fits our needs
  collapse: css({
    padding: theme.spacing(1, 2),
    flexDirection: 'row',
    justifyContent: 'flex-start',
    borderTopLeftRadius: theme.shape.borderRadius(2),
    borderBottomLeftRadius: theme.shape.borderRadius(2),
    '&:hover': {
      backgroundColor: theme.colors.background.secondary,
    },
    ['> div']: {
      width: '100%',
    },
  }),
  stage: css({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  duration: css({
    fontSize: theme.typography.bodySmall.fontSize,
    color: theme.colors.text.secondary,
  }),
  steps: css({
    listStyle: 'none',
    marginLeft: theme.spacing(4),
  }),
  step: css({
    position: 'relative',
    padding: theme.spacing(1, 2, 1, 1),
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTopLeftRadius: theme.shape.borderRadius(2),
    borderBottomLeftRadius: theme.shape.borderRadius(2),
    color: theme.colors.text.secondary,
    '&:hover': {
      backgroundColor: theme.colors.background.secondary,
    },
  }),
  stepContent: css({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  }),
  activeStep: css({
    ['&:before']: {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: '-2px',
      width: theme.spacing(0.5),
      borderRadius: theme.shape.borderRadius(2),
      background: theme.colors.gradients.brandVertical,
    },
    color: theme.colors.text.primary,
    backgroundColor: theme.colors.background.secondary,
  }),
  activeStage: css({
    color: theme.colors.text.primary,
    backgroundColor: theme.colors.background.secondary,
  }),
});

const formatDuration = Intl.DateTimeFormat(undefined, {
  second: 'numeric',
  minute: 'numeric',
}).format;

import { SceneDataTransformer, SceneQueryRunner } from '@grafana/scenes';
import { DATASOURCES } from '../constants';

import { traceCleanup } from 'transformations/traceCleanup';
import { CI, VCS } from 'semanticConventions';

export const getRecentBuildsQuery = (repoSlug: string) =>
  new SceneDataTransformer({
    $data: new SceneQueryRunner({
      datasource: DATASOURCES.TRACES,
      queries: [
        {
          refId: 'A',
          queryType: 'traceql',
          streaming: false,
          query: `{resource.${VCS.GIT.REPO.NAME}="${repoSlug}" && span.${CI.DRONE.BUILD.NUMBER}>=0 && span.${CI.DRONE.WORKFLOW_ITEM.KIND}="build" && span.${CI.WORKFLOW_ITEM.STATUS}!=""}`,
        },
      ],
    }),
    transformations: [
      traceCleanup,
      {
        id: 'groupBy',
        options: {
          fields: {
            [CI.DRONE.BUILD.NUMBER]: {
              aggregations: [],
              operation: 'groupby',
            },
            [CI.WORKFLOW_ITEM.STATUS]: {
              aggregations: [],
              operation: 'groupby',
            },
            duration: {
              aggregations: ['max'],
              operation: 'aggregate',
            },
            time: {
              aggregations: ['lastNotNull'],
              operation: 'aggregate',
            },
          },
        },
      },
      {
        id: 'merge',
        options: {},
      },
      {
        id: 'organize',
        options: {
          excludeByName: {
            'Span ID': true,
            traceIdHidden: true,
            [VCS.GIT.REPO.NAME]: true,
            Name: true,
            [CI.DRONE.WORKFLOW_ITEM.KIND]: true,
          },
          indexByName: {
            [CI.DRONE.BUILD.NUMBER]: 0,
            'time (lastNotNull)': 1,
            [CI.WORKFLOW_ITEM.STATUS]: 7,
            'duration (max)': 8,
          },
          renameByName: {
            [CI.DRONE.BUILD.NUMBER]: 'Build #',
            [CI.WORKFLOW_ITEM.STATUS]: 'Status',
            'time (lastNotNull)': 'Start time',
          },
        },
      },
      {
        id: 'calculateField',
        options: {
          mode: 'binary',
          binary: {
            left: 'duration (max)',
            right: '1000000000',
            operator: '/',
          },
          replaceFields: false,
          alias: 'Duration',
        },
      },
      {
        id: 'organize',
        options: {
          excludeByName: {
            'duration (max)': true,
          },
        },
      },
    ],
  });

import { SceneVariableState } from '@grafana/scenes';
import { DATASOURCES } from '../constants';
import { useDatasourceRefs } from '../utils/utils.plugin';
import { VariableHide } from '@grafana/schema';
import { useMemo } from 'react';

export type SceneConstantVariableState = Omit<SceneVariableState, 'type'>;
export const useDatasourcesSceneVariables = (): SceneConstantVariableState[] => {
  const datasourceUIDs = useDatasourceRefs();

  return useMemo(() => {
    return [
      {
        value: datasourceUIDs.metrics,
        name: DATASOURCES.METRICS.uid.substring(1),
        hide: VariableHide.hideVariable,
      },
      {
        value: datasourceUIDs.logs,
        name: DATASOURCES.LOGS.uid.substring(1),
        hide: VariableHide.hideVariable,
      },
      {
        value: datasourceUIDs.traces,
        name: DATASOURCES.TRACES.uid.substring(1),
        hide: VariableHide.hideVariable,
      },
    ];
  }, [datasourceUIDs.logs, datasourceUIDs.metrics, datasourceUIDs.traces]);
};

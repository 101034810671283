import {
  EmbeddedScene,
  PanelBuilders,
  SceneControlsSpacer,
  SceneDataTransformer,
  SceneGridItem,
  SceneGridLayout,
  SceneQueryRunner,
  SceneRefreshPicker,
  SceneTimePicker,
  SceneTimeRange,
  VariableValueSelectors,
} from '@grafana/scenes';
import { ThresholdsMode } from '@grafana/schema';
import { DATASOURCES, ROUTES } from '../../constants';
import { prefixRoute } from 'utils/utils.routing';
import { JOIN_BY_TIME, LABELS, RENAME_BY_NAME_CONFIG } from 'transformations/common';
import { CI, toPromLabel, VCS } from 'semanticConventions';
import { getBuildsByStatusPanel } from 'panels/buildsByStatus';
import { getBuildsByStatusQuery } from 'queries/buildByStatus';

export const getSummaryNumbersScene = () => {
  const activeBuilds = new SceneDataTransformer({
    $data: new SceneQueryRunner({
      datasource: DATASOURCES.METRICS,
      queries: [
        {
          refId: 'A',
          expr: `sum(ci_builds_number{${toPromLabel(
            CI.WORKFLOW_ITEM.STATUS
          )}=~"running|pending|waiting_on_dependencies"}) by (${toPromLabel(CI.WORKFLOW_ITEM.STATUS)})`,
          range: false,
          instant: true,
          legendFormat: `{{${toPromLabel(CI.WORKFLOW_ITEM.STATUS)}}}`,
        },
      ],
    }),
    transformations: [
      JOIN_BY_TIME,
      {
        id: 'organize',
        options: {
          indexByName: {
            Time: 0,
            running: 1,
            pending: 2,
            waiting_on_dependencies: 3,
          },
          renameByName: RENAME_BY_NAME_CONFIG,
        },
      },
    ],
  });

  return [
    new SceneGridItem({
      x: 0,
      y: 0,
      width: 6,
      height: 8,
      body: PanelBuilders.stat()
        .setTitle('Total number of builds')
        .setOption('text', { titleSize: 20 })
        .setThresholds({ mode: ThresholdsMode.Absolute, steps: [{ value: 0, color: 'blue' }] })
        .build(),
      $data: new SceneQueryRunner({
        queries: [
          {
            refId: 'A',
            datasource: DATASOURCES.METRICS,
            expr: 'sum(ci_builds_number)',
            range: false,
            instant: true,
            legendFormat: `{{${toPromLabel(CI.WORKFLOW_ITEM.STATUS)}}}`,
          },
        ],
      }),
    }),
    new SceneGridItem({
      x: 6,
      y: 0,
      width: 12,
      height: 8,
      $data: activeBuilds,
      body: PanelBuilders.stat()
        .setTitle('Active builds')
        .setOption('text', { titleSize: 20 })
        .setOverrides((overridesBuilder) =>
          overridesBuilder
            .matchFieldsWithName(LABELS.RUNNING)
            .overrideColor({ mode: 'fixed', fixedColor: 'yellow' })
            .matchFieldsWithName(LABELS.PENDING)
            .overrideColor({ mode: 'fixed', fixedColor: '#888' })
            .matchFieldsWithName(LABELS.WAITING_ON_DEPENDENCIES)
            .overrideColor({ mode: 'fixed', fixedColor: '#666' })
        )
        .build(),
    }),
    new SceneGridItem({
      $data: new SceneQueryRunner({
        queries: [
          {
            refId: 'A',
            datasource: DATASOURCES.METRICS,
            expr: 'ci_restarts_total',
          },
        ],
      }),
      x: 18,
      y: 0,
      width: 6,
      height: 8,
      body: PanelBuilders.stat()
        .setTitle('Restarts within timerange')
        .setOption('reduceOptions', {
          values: false,
          calcs: ['diff'],
          fields: '/^ci_restarts_total$/',
        })
        .build(),
    }),
    new SceneGridItem({
      x: 0,
      y: 8,
      width: 24,
      height: 8,
      $data: getBuildsByStatusQuery('.+'),
      body: getBuildsByStatusPanel(),
    }),
    // TODO: revisit the following
    new SceneGridItem({
      x: 0,
      y: 16,
      width: 24,
      height: 10,
      body: PanelBuilders.table()
        .setTitle('Monitored repositories')
        .setOption('showHeader', false)
        .setLinks([
          {
            title: `Overview for the \${__data.fields.${toPromLabel(VCS.GIT.REPO.NAME)}} repository`,
            url:
              prefixRoute(ROUTES.RepositoriesOverview) +
              `?var-repo=\${__data.fields.${toPromLabel(VCS.GIT.REPO.NAME)}}\${__url.params}&\${__url_time_range}`,
          },
        ])
        .build(),
      $data: new SceneDataTransformer({
        $data: new SceneQueryRunner({
          queries: [
            {
              refId: 'A',
              datasource: DATASOURCES.METRICS,
              expr: `ci_repo_info{${toPromLabel(CI.WORKFLOW_ITEM.STATUS)}!=""}`,
              legendFormat: `{{${toPromLabel(VCS.GIT.REPO.NAME)}}}`,
              format: 'table',
            },
          ],
        }),
        transformations: [
          {
            id: 'groupBy',
            options: {
              fields: {
                [toPromLabel(VCS.GIT.REPO.NAME)]: {
                  aggregations: ['lastNotNull'],
                  operation: 'groupby',
                },
              },
            },
          },
        ],
      }),
    }),
  ];
};

export const getOverviewScene = () => {
  return new EmbeddedScene({
    $timeRange: new SceneTimeRange({ from: 'now-7d', to: 'now' }),
    controls: [
      new VariableValueSelectors({}),
      new SceneControlsSpacer(),
      new SceneTimePicker({ isOnCanvas: true }),
      new SceneRefreshPicker({ isOnCanvas: true }),
    ],
    body: new SceneGridLayout({
      children: getSummaryNumbersScene(),
    }),
  });
};

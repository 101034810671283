import React, { PropsWithChildren } from 'react';
import { SceneComponentProps, sceneGraph, SceneObjectBase } from '@grafana/scenes';
import { DataFrameView, GrafanaTheme2, PanelData, sortDataFrame } from '@grafana/data';
import { css } from '@emotion/css';
import { CustomScrollbar, useStyles2 } from '@grafana/ui';
import notfound from '../../../../../../../../../../../img/notfound.svg';
import { LoadingState } from '@grafana/schema';

export class LogsView extends SceneObjectBase {
  static Component = LogsViewRenderer;

  constructor() {
    super({});
  }
}

function LogsViewRenderer({ model }: SceneComponentProps<LogsView>) {
  const styles = useStyles2(getStyles);
  const { data: logData } = sceneGraph.getData(model).useState();
  const { data: traceData } = sceneGraph.getData(model.parent!.parent!).useState();

  if (logData?.state === LoadingState.Error || traceData?.state === LoadingState.Error) {
    return (
      <LogsContainer>
        <ErrorMessage
          title="Error retrieving logs"
          subtitle="There was an error while retrieving logs. Try in a few moments."
        />
      </LogsContainer>
    );
  }

  // TODO: Loading state
  if (
    !logData?.series[0] ||
    !traceData?.series[0] ||
    logData?.state === LoadingState.Loading ||
    traceData?.state === LoadingState.Loading
  ) {
    return <LogsContainer />;
  }

  const view = new DataFrameView(sortDataFrame(logData.series[0], 3, false));

  const spanStartTime = getSpanStartTime(traceData, view.fields.labels.values[0]?.spanid);

  return (
    <LogsContainer>
      {view.fields.Line.values.length > 0 ? (
        <CustomScrollbar>
          <ul className={styles.content}>
            {view.fields.Line.values.map((line: string, index) => (
              <li key={view.fields.id.values[index]} className={styles.row}>
                <span className={styles.line}>{index + 1}</span>
                <pre className={styles.message}>{line}</pre>
                <span className={styles.time}>{formatTime(view.fields.Time.values[index], spanStartTime)}</span>
              </li>
            ))}
          </ul>
        </CustomScrollbar>
      ) : (
        // The log query did not return any result.
        <ErrorMessage
          title="Step logs not found"
          subtitle="We're looking but can't seem to find logs for this step. Try in a few moments."
          type="not-found"
        />
      )}
    </LogsContainer>
  );
}

/**
 * Gets the start time of the span with the given `spanId` from the trace in `traceData`.
 *
 * @param traceData The trace data.
 * @param spanId The span ID.
 * @returns The start time of the span, or `undefined` if the span ID is not found.
 *
 */
function getSpanStartTime(traceData: PanelData, spanId: string): number | undefined {
  const spanIndex = traceData.series[0].fields.find((field) => field.name === 'spanID')?.values.indexOf(spanId);
  if (!spanIndex || spanIndex === -1) {
    return undefined;
  }

  return traceData.series[0].fields.find((field) => field.name === 'startTime')?.values[spanIndex];
}

/**
 * Returns a string representing the number of seconds elapsed from `time` since `relativeTo`.
 * If `relativeTo` is not provided, an absolutely formated time is returned.
 *
 * @param time The trace data.
 * @param relativeTo The span ID.
 * @returns The start time of the span, or `undefined` if the span ID is not found.
 *
 */
const formatTime = (time: number, relativeTo?: number) => {
  if (relativeTo) {
    return `${Math.floor((time - relativeTo) / 1000)}s`;
  }

  return dateTimeFormat(time);
};

const dateTimeFormat = Intl.DateTimeFormat('en-US', {
  second: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}).format;

const getStyles = (theme: GrafanaTheme2) => ({
  content: css({
    display: 'table',
    width: '100%',
    fontFamily: theme.typography.fontFamilyMonospace,
  }),
  row: css({
    display: 'table-row',
  }),
  line: css({
    color: theme.colors.text.disabled,
    padding: theme.spacing(0, 1),
    display: 'table-cell',
    width: 0,
    textAlign: 'right',
    userSelect: 'none',
  }),
  message: css({
    padding: theme.spacing(0, 1, 0, 2),
    color: theme.colors.text.primary,
    wordBreak: 'break-word',
    backgroundColor: 'transparent',
    display: 'table-cell',
    border: 'none',
  }),
  time: css({
    color: theme.colors.text.disabled,
    padding: theme.spacing(0, 1),
    display: 'table-cell',
    whiteSpace: 'nowrap',
    width: 0,
    textAlign: 'right',
    userSelect: 'none',
  }),
});

const getNotFoundStyles = (theme: GrafanaTheme2) => ({
  root: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    overflow: 'hidden',
  }),
  title: css({
    fontSize: theme.typography.h1.fontSize,
    fontWeight: theme.typography.h1.fontWeight,
    color: theme.colors.text.primary,
    marginBottom: theme.spacing(1),
    textAlign: 'center',
  }),
  message: css({
    fontSize: theme.typography.h4.fontSize,
    color: theme.colors.text.secondary,
    marginBottom: theme.spacing(8),
    textAlign: 'center',
  }),
});

interface ErrorMessageProps {
  title: string;
  subtitle: string;
  type?: 'not-found';
}
function ErrorMessage({ title, subtitle, type }: ErrorMessageProps) {
  const styles = useStyles2(getNotFoundStyles);

  return (
    <div className={styles.root}>
      <span className={styles.title}>{title}</span>
      <p className={styles.message}>{subtitle}</p>

      {type === 'not-found' && <img src={notfound} alt="" />}
    </div>
  );
}

const getLogsContainerStyles = (theme: GrafanaTheme2) =>
  css({
    backgroundColor: theme.colors.background.canvas,
    width: '100%',
    borderRadius: theme.shape.borderRadius(1),
    borderColor: theme.colors.border.weak,
    borderWidth: '1px',
    borderStyle: 'solid',
  });

function LogsContainer({ children }: PropsWithChildren<{}>) {
  const style = useStyles2(getLogsContainerStyles);

  return <div className={style}>{children}</div>;
}

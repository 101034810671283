import { css } from '@emotion/css';
import React from 'react';

import { SceneObjectBase, SceneComponentProps, SceneObjectState, SceneObject } from '@grafana/scenes';
import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

interface BuildLayoutState extends SceneObjectState {
  children: SceneObject[];
}
export class BuildLayout extends SceneObjectBase<BuildLayoutState> {
  public static Component = BuildLayoutRenderer;
}

function BuildLayoutRenderer({ model }: SceneComponentProps<BuildLayout>) {
  const { children } = model.useState();
  const styles = useStyles2(getLayoutStyles);

  return (
    <div className={styles.layoutRoot}>
      <div className={styles.layoutInner}>
        {children.map((item) => {
          return <item.Component key={item.state.key} model={item} />;
        })}
      </div>
    </div>
  );
}

export interface BuildLayoutItemState extends SceneObjectState {
  body: SceneObject;
}

export class BuildLayoutItem extends SceneObjectBase<BuildLayoutItemState> {
  public static Component = BuildLayoutItemRenderer;
}

function BuildLayoutItemRenderer({ model }: SceneComponentProps<BuildLayoutItem>) {
  const { body } = model.useState();

  if (!body) {
    return null;
  }

  return <body.Component model={body} />;
}

const getLayoutStyles = (theme: GrafanaTheme2) => {
  return {
    layoutRoot: css({
      label: 'root',
      position: 'relative',
      width: '100%',
    }),
    layoutInner: css({
      label: 'inner',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      gap: theme.spacing(1),
    }),
  };
};

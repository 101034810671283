import { PanelBuilders } from '@grafana/scenes';
import { MappingType } from '@grafana/schema';
import { TableCellDisplayMode } from '@grafana/ui';
import { ROUTES } from '../constants';
import { LABELS } from 'transformations/common';
import { prefixRoute } from 'utils/utils.routing';

export const getRecentBuildsPanel = (repoSlug: string) =>
  PanelBuilders.table()
    .setTitle('Recent builds')
    .setOverrides((builder) => {
      builder
        .matchFieldsWithName('Build #')
        .overrideLinks([
          {
            title: 'View build ${__value.text}',
            url:
              prefixRoute(ROUTES.RepositoriesOverview) +
              `/${repoSlug}/builds/` +
              '${__value.text}${__url.params:include:from,to}',
          },
        ])
        .matchFieldsWithName('Status')
        .overrideMappings([
          {
            type: MappingType.ValueToText,
            options: {
              declined: {
                text: LABELS.DECLINED,
                color: 'orange',
              },
              error: { text: LABELS.ERROR, color: 'dark-red' },
              failure: { text: LABELS.FAILURE, color: 'semi-dark-red' },
              killed: { text: LABELS.KILLED, color: 'red' },
              skipped: { text: LABELS.SKIPPED, color: '#444' },
              success: { text: LABELS.SUCCESS, color: 'green' },
            },
          },
        ])
        .overrideCustomFieldConfig('cellOptions', {
          type: TableCellDisplayMode.ColorBackground,
        })
        .matchFieldsWithName('Duration')
        .overrideUnit('dthms')
        .build();
    })
    .build();

import React, { useMemo } from 'react';
import { SceneApp, SceneAppPage, SceneVariableSet, ConstantVariable } from '@grafana/scenes';
import { ROUTES } from '../../constants';
import { prefixRoute } from 'utils/utils.routing';
import { getOverviewScene } from './scenes';
import { name } from '../../plugin.json';

import { SceneConstantVariableState, useDatasourcesSceneVariables } from 'pages/utils';

const getScene = (initialVariables: SceneConstantVariableState[]) => {
  const appPage = new SceneAppPage({
    $variables: new SceneVariableSet({
      variables: initialVariables.map((state) => new ConstantVariable(state)),
    }),
    title: name,
    url: prefixRoute(`${ROUTES.Overview}`),
    getScene: getOverviewScene,
  });

  return new SceneApp({
    pages: [appPage],
  });
};

export const Overview = () => {
  const variables = useDatasourcesSceneVariables();

  const scene = useMemo(() => getScene(variables), [variables]);

  return <scene.Component model={scene} />;
};

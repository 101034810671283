import { CustomVariable, EmbeddedScene, SceneQueryRunner, SceneTimeRange, SceneVariableSet } from '@grafana/scenes';
import { SideNav } from './components/SideNav';
import { LogsView } from './components/LogsView';
import { DATASOURCES } from '../../../../../../../../../../constants';
import { BuildLayout, BuildLayoutItem } from './components/BuildLayout';
import { getStages } from './utils';

export const getBuildTab =
  (repoOrg: string, repoName: string, buildNumber: string, timeRange: SceneTimeRange, traceQuery: SceneQueryRunner) =>
  () => {
    const spanIdVar = new CustomVariable({
      name: 'spanID',
    });
    const variables = new SceneVariableSet({
      variables: [spanIdVar],
    });

    const lokiQuery = new SceneQueryRunner({
      datasource: DATASOURCES.LOGS,
      $timeRange: timeRange,
      queries: [],
    });

    traceQuery.subscribeToState((state) => {
      if (state.data?.series.length !== 1) {
        return;
      }

      const stages = getStages(state.data);

      const firstStepSpanID = stages?.[0]?.steps[0]?.spanID;

      if (!spanIdVar.getValue() && firstStepSpanID !== undefined) {
        spanIdVar.changeValueTo(firstStepSpanID);
      }

      lokiQuery.setState({
        queries: [
          {
            refId: 'A',
            expr: `{exporter="OTLP"} | json | spanid="$spanID" | line_format "{{.body}}"`,
          },
        ],
      });

      lokiQuery.runQueries();
    });

    return new EmbeddedScene({
      body: new BuildLayout({
        $variables: variables,
        children: [
          new BuildLayoutItem({
            body: new SideNav({
              buildNumber,
              repoName,
              repoOrg,
            }),
          }),
          new BuildLayoutItem({
            $data: lokiQuery,
            body: new LogsView(),
          }),
        ],
      }),
    });
  };

import { EmbeddedScene, SceneGridItem, SceneGridLayout } from '@grafana/scenes';
import { getRecentBuildsPanel } from 'panels/recentBuilds';
import { percentageSuccessfulBuild, statusTimelineData } from 'pages/RepositoriesOverview/data';
import { getStagesTable, getStatusTimelinePanel } from 'pages/RepositoriesOverview/panels';
import { getRecentBuildsQuery } from 'queries/recentBuilds';
import { getBuildsByStatusPanel } from 'panels/buildsByStatus';
import { getBuildsByStatusQuery } from 'queries/buildByStatus';

export const getRepositoryOverviewScene = (repoSlug: string) => {
  const ROW_HEIGHT = 8;
  const PANEL_WIDTH = 12;

  return new EmbeddedScene({
    body: new SceneGridLayout({
      children: [
        new SceneGridItem({
          x: 0,
          y: 0,
          width: 24,
          height: ROW_HEIGHT,
          $data: statusTimelineData(repoSlug),
          body: getStatusTimelinePanel(),
        }),
        new SceneGridItem({
          x: 0,
          y: 0,
          width: 24,
          height: ROW_HEIGHT,
          $data: getBuildsByStatusQuery(repoSlug),
          body: getBuildsByStatusPanel(),
        }),

        new SceneGridItem({
          y: ROW_HEIGHT,
          x: 0,
          height: ROW_HEIGHT,
          width: PANEL_WIDTH,
          $data: percentageSuccessfulBuild(repoSlug),
          body: getStagesTable(),
        }),
        new SceneGridItem({
          y: ROW_HEIGHT,
          x: PANEL_WIDTH,
          height: ROW_HEIGHT,
          width: PANEL_WIDTH,
          $data: getRecentBuildsQuery(repoSlug),
          body: getRecentBuildsPanel(repoSlug),
        }),
      ],
    }),
  });
};

import { EmbeddedScene, PanelBuilders, SceneFlexItem, SceneFlexLayout, SceneQueryRunner } from '@grafana/scenes';
import { DATASOURCES } from '../../../../../../constants';
import {
  BarGaugeDisplayMode,
  FieldColorModeId,
  GraphDrawStyle,
  ThresholdsMode,
  VisibilityMode,
  VizOrientation,
} from '@grafana/schema';
import { CI, toPromLabel, VCS } from 'semanticConventions';

export const getStageScene = (repoSlug: string, stageName: string) => () => {
  return new EmbeddedScene({
    body: new SceneFlexLayout({
      direction: 'column',
      children: getPanels(repoSlug, stageName),
    }),
  });
};

const getQueries = (repoSlug: string, stage: string) => {
  const stageExecutionTime = new SceneQueryRunner({
    datasource: DATASOURCES.METRICS,
    queries: [
      {
        refId: 'A',
        expr: `sum(ci_duration_sum{${toPromLabel(VCS.GIT.REPO.NAME)}="${repoSlug}", ${toPromLabel(
          CI.DRONE.STAGE.NAME
        )}="${stage}", ${toPromLabel(CI.DRONE.WORKFLOW_ITEM.KIND)}="step"} / ci_duration_count{${toPromLabel(
          VCS.GIT.REPO.NAME
        )}="${repoSlug}", ${toPromLabel(CI.DRONE.STAGE.NAME)}="${stage}", ${toPromLabel(
          CI.DRONE.WORKFLOW_ITEM.KIND
        )}="step"})
        `,
        range: true,
        instant: false,
        legendFormat: `Execution time`,
      },
    ],
  });

  const stepsAverageExecutionTime = new SceneQueryRunner({
    datasource: DATASOURCES.METRICS,
    queries: [
      {
        refId: 'A',
        expr: `sum(ci_duration_sum{${toPromLabel(VCS.GIT.REPO.NAME)}="${repoSlug}", ${toPromLabel(
          CI.DRONE.STAGE.NAME
        )}="${stage}", ${toPromLabel(CI.DRONE.WORKFLOW_ITEM.KIND)}="step"} / ci_duration_count{${toPromLabel(
          VCS.GIT.REPO.NAME
        )}="${repoSlug}", ${toPromLabel(CI.DRONE.STAGE.NAME)}="${stage}", ${toPromLabel(
          CI.DRONE.WORKFLOW_ITEM.KIND
        )}="step"}) by (span_name)`,

        legendFormat: '{{span_name}}',
      },
    ],
  });

  const failedStepsCount = new SceneQueryRunner({
    datasource: DATASOURCES.METRICS,
    queries: [
      {
        refId: 'A',
        expr: `count(ci_duration_count{${toPromLabel(VCS.GIT.REPO.NAME)}="${repoSlug}", ${toPromLabel(
          CI.DRONE.STAGE.NAME
        )}="${stage}", status_code="STATUS_CODE_ERROR"}) by (span_name)`,
        legendFormat: '{{span_name}}',
      },
    ],
  });

  return { stepsAverageExecutionTime, stageExecutionTime, failedStepsCount };
};

const getPanels = (repoSlug: string, stage: string) => {
  const { stageExecutionTime, stepsAverageExecutionTime, failedStepsCount } = getQueries(repoSlug, stage);

  return [
    new SceneFlexItem({
      $data: stageExecutionTime,
      body: PanelBuilders.timeseries()
        .setTitle('Execution time')
        .setUnit('ms')
        .setCustomFieldConfig('drawStyle', GraphDrawStyle.Line)
        .setCustomFieldConfig('lineWidth', 1)
        .setCustomFieldConfig('lineStyle', {
          fill: 'solid',
        })
        .setCustomFieldConfig('spanNulls', true)
        .setCustomFieldConfig('showPoints', VisibilityMode.Always)
        .setMin(0)
        .build(),
    }),
    new SceneFlexItem({
      body: new SceneFlexLayout({
        children: [
          new SceneFlexItem({
            $data: stepsAverageExecutionTime,
            body: PanelBuilders.bargauge()
              .setTitle('Average step execution time')
              .setDisplayName('${__series.name}') // Make the step name visible when there is only one
              .setOption('displayMode', BarGaugeDisplayMode.Lcd)
              .setOption('orientation', VizOrientation.Horizontal)
              .setUnit('ms')
              .setThresholds({
                mode: ThresholdsMode.Percentage,
                steps: [
                  {
                    value: 0,
                    color: 'green',
                  },
                  {
                    value: 50,
                    color: 'orange',
                  },
                  {
                    value: 80,
                    color: 'red',
                  },
                ],
              })
              .setColor({
                mode: FieldColorModeId.Thresholds,
              })
              .build(),
          }),
          new SceneFlexItem({
            $data: failedStepsCount,
            body: PanelBuilders.bargauge()
              .setTitle('Failed step executions')
              .setDisplayName('${__series.name}') // Make the step name visible when there is only one
              .setOption('displayMode', BarGaugeDisplayMode.Lcd)
              .setOption('orientation', VizOrientation.Horizontal)
              .setColor({
                mode: FieldColorModeId.Fixed,
                fixedColor: 'red',
              })
              .build(),
          }),
        ],
      }),
    }),
  ];
};

import { LABELS } from 'transformations/common';

// TODO: better tpying
export const colorByStatusOverrideBuilder = (overridesBuilder: any) =>
  overridesBuilder
    .matchFieldsWithName(LABELS.SUCCESS)
    .overrideColor({ mode: 'fixed', fixedColor: 'green' })
    .matchFieldsWithName(LABELS.RUNNING)
    .overrideColor({ mode: 'fixed', fixedColor: 'yellow' })
    .matchFieldsWithName(LABELS.KILLED)
    .overrideColor({ mode: 'fixed', fixedColor: 'red' })
    .matchFieldsWithName(LABELS.FAILURE)
    .overrideColor({ mode: 'fixed', fixedColor: 'semi-dark-red' })
    .matchFieldsWithName(LABELS.ERROR)
    .overrideColor({ mode: 'fixed', fixedColor: 'dark-red' })
    .matchFieldsWithName(LABELS.DECLINED)
    .overrideColor({ mode: 'fixed', fixedColor: 'orange' })
    .matchFieldsWithName(LABELS.BLOCKED)
    .overrideColor({ mode: 'fixed', fixedColor: 'dark-orange' })
    .matchFieldsWithName(LABELS.PENDING)
    .overrideColor({ mode: 'fixed', fixedColor: '#888' })
    .matchFieldsWithName(LABELS.WAITING_ON_DEPENDENCIES)
    .overrideColor({ mode: 'fixed', fixedColor: '#666' })
    .matchFieldsWithName(LABELS.SKIPPED)
    .overrideColor({ mode: 'fixed', fixedColor: '#444' });

import { QueryVariable, SceneDataTransformer, SceneQueryRunner } from '@grafana/scenes';
import { DATASOURCES } from '../../constants';
import { VariableRefresh } from '@grafana/schema';
import { CI, toPromLabel, VCS } from 'semanticConventions';

export const percentageSuccessfulBuild = (repoSlug: string) =>
  new SceneDataTransformer({
    $data: new SceneQueryRunner({
      datasource: DATASOURCES.METRICS,
      queries: [
        {
          refId: 'A',
          format: 'table',
          instant: true,
          // These metrics are named differently locally vs in staging
          expr: `sum by(span_name) (ci_duration_count{${toPromLabel(VCS.GIT.REPO.NAME)}="${repoSlug}", ${toPromLabel(
            VCS.GIT.BRANCH.NAME
          )}=~"$branch", status_code="STATUS_CODE_OK", ${toPromLabel(
            CI.DRONE.WORKFLOW_ITEM.KIND
          )}="stage"} != 0) / sum by(span_name) (ci_duration_count{${toPromLabel(
            VCS.GIT.REPO.NAME
          )}="${repoSlug}", ${toPromLabel(VCS.GIT.BRANCH.NAME)}=~"$branch", ${toPromLabel(
            CI.DRONE.WORKFLOW_ITEM.KIND
          )}="stage"} != 0)`,
        },
      ],
    }),
    transformations: [
      {
        id: 'organize',
        options: {
          excludeByName: {
            Time: true,
          },
          renameByName: {
            span_name: 'Stage name',
            Value: 'Successful builds',
          },
        },
      },
    ],
  });

export const statusTimelineData = (repoName: string) => {
  return new SceneDataTransformer({
    $data: new SceneQueryRunner({
      datasource: DATASOURCES.METRICS,
      queries: [
        {
          refId: 'A',
          expr: `ci_repo_info{${toPromLabel(VCS.GIT.REPO.NAME)}="${repoName}", ${toPromLabel(
            VCS.GIT.BRANCH.NAME
          )}=~"$branch"}`,
          range: true,
          instant: false,
          format: 'table',
          legendFormat: '__auto',
        },
      ],
    }),
    transformations: [
      {
        id: 'groupingToMatrix',
        options: {
          columnField: toPromLabel(VCS.GIT.BRANCH.NAME),
          rowField: 'Time',
          valueField: toPromLabel(CI.WORKFLOW_ITEM.STATUS),
        },
      },
      {
        id: 'convertFieldType',
        options: {
          fields: {},
          conversions: [
            {
              targetField: `Time\\${toPromLabel(VCS.GIT.BRANCH.NAME)}`,
              destinationType: 'time',
            },
          ],
        },
      },
    ],
  });
};

export const getBranchQueryVariable = (repoSlug: string) =>
  new QueryVariable({
    datasource: DATASOURCES.METRICS,
    query: {
      refId: 'A',
      qryType: 1,
      query: `label_values(ci_repo_info{${toPromLabel(VCS.GIT.REPO.NAME)}="${repoSlug}"}, ${toPromLabel(
        VCS.GIT.BRANCH.NAME
      )})`,
    },
    name: 'branch',
    label: 'Branch',
    includeAll: true,
    defaultToAll: true,
    refresh: VariableRefresh.never,
    allValue: '.+',
    isMulti: true,
  });

export const getRepoQueryVariable = () =>
  new QueryVariable({
    datasource: DATASOURCES.METRICS,
    query: {
      refId: 'B',
      qryType: 1,
      query: `label_values(ci_repo_info, ${toPromLabel(VCS.GIT.REPO.NAME)})`,
    },
    name: 'repo',
    label: 'Repository',
    refresh: VariableRefresh.never,
  });
